<template>
  <div class="media-list">xxxx</div>
</template>

<script>
export default {
  mounted() {
    console.log(this.$route.params)
  }
}
</script>

<style>

</style>